.pic-ctn {
  width: 50%;
  height: 100%;
}

@keyframes display {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  30% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.pic-ctn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  /* padding: 50px; */
  /* padding-top: 1px; */
  height: 100%;
  /* padding-right: 500px; */
  object-fit: cover;
}

.pic-ctn > img {
  position: absolute;
  opacity: 0;
  animation: display 20s infinite;
  width: 400px;
  height: 400px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 50%;
  object-fit: cover;
  /* border: 1px solid white; */
}

img:nth-child(2) {
  animation-delay: 4s;
}
img:nth-child(3) {
  animation-delay: 8s;
}
img:nth-child(4) {
  animation-delay: 12s;
}
img:nth-child(5) {
  animation-delay: 16s;
}

@media only screen and (max-width: 600px) {
}
