@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}

#root {
  height: 100%;
  background-color: black;
  /* overflow: hidden; */
  /* border: 10px dotted white; */
}

#landing-wrapper {
  width: 100%;
  height: 2600px;
  /* background-color: rgb(210, 10, 255); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  /* background-color: #484848; */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%235d5d5d' fill-opacity='0.28'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */
}

.landing-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-weight: 800;
  background-color: rgb(69, 129, 75);
  /* height: 800px; */
  height: 800px;
  width: 100%;
  /* padding-top: 150px; */

  color: white;
}

.landing-2-top {
  /* min-height: 50%; */
  height: 900px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition-duration: 0.5s;
}

@keyframes fromLeft {
  0% {
    opacity: 0;
    padding-left: 0px;
  }

  100% {
    opacity: 1;
    padding-left: 30px;
  }
}
@keyframes fromRight {
  0% {
    opacity: 0;
    padding-right: 0px;
  }

  100% {
    opacity: 1;
    padding-right: 30px;
  }
}

.globe-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 125px; */
  width: 100%;
  padding-bottom: 70px;
}

.left-2-main-text {
  font-size: 5rem;
  color: white;
}
.left-2-second-text {
  font-size: 1.5rem;
  font-style: italic;
  width: 800px;
  /* color: rgb(113, 113, 113); */
  font-family: "Courier New", Courier, monospace;
}

.landing-2-wrapper {
  width: 100%;
  max-width: 100%;
  background-color: rgb(112, 165, 208);

  color: white;
}

.landing-text {
  font-family: Helvetica;
  font-size: 4rem;
  width: 600px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
  /* color: white; */
  height: 100%;
  justify-content: center;
  opacity: 1;
  /* padding-top: px; */
}

@keyframes slideIn {
  0% {
    margin-right: 30px;
    opacity: 0;
  }

  100% {
    margin-right: 0px;
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    padding-top: 10px;
    opacity: 0;
    height: 250px;
  }

  75% {
    opacity: 1;
    height: 250px;
  }

  100% {
    padding-top: 0px;
    opacity: 1;
  }
}

.landing-text > p:nth-child(1) {
  animation: slideIn 1s forwards;
  animation-delay: 1.5s;
  opacity: 0;
  /* margin-right: px; */
  transition-duration: 1s;
}

.landing-bottom-text {
  opacity: 0;
  height: 250px;
  animation: slideUp 1s forwards;
  animation-delay: 2s;
}

.logo {
  animation: hopHop 1.5s forwards;
  animation-delay: 1.6;
  transition-duration: 0.2s;
}

@keyframes shrink {
  0% {
    height: 300px;
  }
  99% {
    width: 300px;
  }
  100% {
    height: 0px;
    width: 0px;
  }
}

.logo-container {
  display: flex;
  gap: 15px;
}

.logo-container:before {
  content: "💕";
  width: 10px;
  height: 10px;
  position: absolute;
  margin-left: 305px;
  margin-bottom: 500px;
  animation: shootUp 2s forwards;
  animation-delay: 2s;
  /* font-size: 2rem; */
  opacity: 0;
}

@keyframes shootUp {
  0% {
    opacity: 0%;
    margin-top: 0px;
  }

  25% {
    opacity: 0%;
    margin-top: 0px;
  }

  65% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
    margin-top: -50px;
  }
}

@keyframes hopHop {
  0% {
    margin-left: 25vw;
    margin-bottom: 0px;
  }

  10% {
    margin-bottom: 10px;
  }
  20% {
    margin-bottom: 0px;
  }
  35% {
    margin-bottom: 10px;
  }
  40% {
    margin-bottom: 0px;
  }
  50% {
    margin-bottom: 18px;
  }
  65% {
    margin-bottom: 18px;
    margin-right: 0px;
  }
  75% {
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 25vw;
  }
  100% {
    margin-left: 0px;
    margin-right: 10px;
  }
}

.buttons {
  display: flex;
  gap: 7px;
  width: 100%;
  /* margin-top: 20px; */
  margin-top: -10px;
  justify-content: flex-end;
  animation: fadeIn 1s forwards;
  opacity: 0;
  animation-delay: 4s;
}
/* CSS */
.landing-btn {
  background-color: #76794d;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: white;
  font-family: Helvetica;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* border: 1px solid black; */
}

.landing-btn:hover {
  background-color: rgb(185, 111, 111);
}

.landing-btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.landing-btn:focus-visible {
  box-shadow: none;
}

.git-logo,
.discord-logo {
  display: inline;
  margin-right: 5px;
  padding-bottom: 2px;
}

.landing-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 900px;
  background-color: rgb(245, 200, 214);
  gap: 35px;
  /* padding-top: 250px; */
  /* flex-direction: column; */
}

.landing-3-icon {
  opacity: 85%;
}

.landing-3-circle-1,
.landing-3-circle-2,
.landing-3-circle-3,
.landing-3-circle-4 {
  /* width: 750px; */
  height: 650px;
  background-color: #99c182;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 15px;
  transition-duration: 0.5s;
  opacity: 0;
  color: rgb(255, 255, 255);
  /* border: 2px solid rgb(45, 45, 45); */
  border-radius: 10px;
  padding: 20px;
  padding-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.right-3-share-wrapper {
  margin-right: 0;
  margin-left: 220px;
}

.right-3-text-wrapper {
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.coords-text-wrapper,
.region-text-wrapper,
.species-text-wrapper,
.random-text-wrapper {
  display: flex;
  justify-content: center;
  width: 320px;
  text-align: center;
  font-size: 1.15rem;
  font-style: italic;
  font-family: "Courier New", Courier, monospace;
  border-radius: 5px;
  /* padding-top: 20px; */
}

.speciesIcon,
.coordsIcon,
.mapIcon {
  height: 100px;
  width: 100px;
  padding: 8px;
  /* margin-bottom: 35px; */
}

.icon-text {
  margin-top: 250px;
  /* color: white; */
  font-family: Helvetica;
  font-weight: 600;
  font-size: 1.5rem;
}

/* Landing ends */
/* ---------------------------------------------------------------------------- */
/* Main app begins */

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

#birdbot-logo {
  font-size: 4rem;
  font-family: "Courier New", Courier, monospace;
}

.sightings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  background-color: rgb(48, 48, 48);
  align-items: center;
  justify-content: center;
}
.sightings-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 20px;
  background-color: rgb(31, 31, 31);
  font-size: 0.75rem;
  font-family: "Courier New", Courier, monospace;
  color: white;
}

.ginkgo-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.header-logo {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid black;
}

.sightings {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  padding-bottom: 50px;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  /* align-items: center; */
  overflow-x: auto;
  background-color: rgb(48, 48, 48);
  padding-top: 10px;
  gap: 5px;
}

.sighting-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 10px;
  min-height: 765px;
  height: 815px;
  min-width: 600px;
  width: 800px;
  /* margin: 0; */
  /* padding: 20px; */
  border-radius: 5px;
  transition-duration: 0.2s;
}

.sighting-wrapper:hover {
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  transform: translateY(-10px);
}

.sighting-wrapper:hover > .portrait-wrapper {
  opacity: 100%;
}

.sighting-wrapper:hover > .portrait-wrapper > .portrait {
  border: 1px solid white;
}

.sighting-wrapper:hover > .sighting-bottom {
  opacity: 100%;
  padding-top: 5px;
  display: flex;
}

.sighting-wrapper:hover
  > .sighting-bottom
  > .location-wrapper
  > .location-name {
  opacity: 100%;
}

.portrait-wrapper {
  width: 800px;
  height: 765px;
  animation: fadeIn 1s linear;
  opacity: 85%;
  border-radius: 5px;
}

.portrait {
  width: 800px;
  /* Change this height to mess with space betwe */
  height: 765px;
  object-fit: cover;
  transition: opacity 2s;
  border-radius: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sighting-bottom {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  height: 140px;
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
  /* padding-left: */
  color: white;
  transition-duration: 0.2s;
  opacity: 0;
  margin-top: -150px;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.336);
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  /* width: 100%; */
}

.com-name {
  font-size: 2rem;
  font-weight: 600;
  /* background-color: rgb(39, 39, 39); */
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.speciesCode {
  font-size: 0.9rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 400;
  font-style: normal;
}

.sci-name {
  font-style: italic;
  font-size: 0.9rem;
  font-family: "Courier New", Courier, monospace;
  /* margin-bottom: 2px; */
}

.number {
  align-self: center;
  text-align: center;
  padding-right: 10px;
}

.location-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
}

.location-name {
  font-family: "Courier New", Courier, monospace;
  font-size: 0.75rem;
  text-align: center;
  color: white;
}
.lds-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 800px;
  height: 765px;
  background-color: rgb(247, 247, 247);
  border-radius: 5px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-left: 360px;
  margin-top: 325px;
  /* background: rgb(247, 247, 247); */
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  align-self: center;
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.no-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(247, 247, 247);
  color: rgba(173, 216, 230, 0.234);
  opacity: 0.25s;
  width: 600px;
  height: 765px;
}

.sizes {
  display: flex;

  /* margin-left: 50px; */
}

.citation {
  color: rgb(202, 202, 202);
  font-size: 0.7rem;
  font-family: "Courier New", Courier, monospace;
  width: 100%;
  text-align: right;
  font-style: italic;
  padding-bottom: 2px;
  height: 18px;
  padding-right: 2px;
  /* background-color: rgb(31, 31, 31); */
}

@media only screen and (max-width: 600px) {
  .landing-1,
  .landing-2-wrapper,
  .landing-3,
  .landing-4 {
    width: 100vw;
  }

  .landing-1 {
    flex-direction: column;
  }

  .logo {
    transform: scale(0.8);
  }

  .landing-text {
    font-size: 2rem;
    height: fit-content;
    align-items: center;
    text-align: center;
  }

  .buttons {
    justify-content: center;
    margin-top: -120px;
  }

  .logo {
    animation: hopHopMobile 1.5s forwards;
  }

  @keyframes hopHopMobile {
    0% {
      padding-top: 500px;
      margin-bottom: 0px;
    }

    10% {
      margin-bottom: 10px;
    }
    20% {
      margin-bottom: 0px;
    }
    35% {
      margin-bottom: 10px;
    }
    40% {
      margin-bottom: 0px;
    }
    50% {
      margin-bottom: 18px;
    }
    65% {
      margin-bottom: 18px;
    }
    75% {
      margin-bottom: 0px;
      padding-top: 500px;
    }
    100% {
      padding-top: 0px;
    }
  }

  .landing-2-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .landing-2-top {
    flex-direction: column;
    align-items: space-between;
    overflow-x: hidden;
  }

  .pic-ctn {
    overflow-x: hidden;
  }

  .pic-ctn > img {
    width: 250px;
    height: 250px;
  }

  .globe-text {
    width: 100vw;
    align-items: center;
  }

  .left-2-main-text {
    font-size: 2.5rem;
    text-align: center;
  }

  .left-2-second-text {
    font-size: 1rem;
    text-align: center;
    padding-bottom: 20px;
    width: 80vw;
  }

  .landing-3 {
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */

    /* gap: 0px; */
    /* padding-top: 75px; */
    /* padding-top: 100px; */
  }
  .landing-3-circle-1,
  .landing-3-circle-2,
  .landing-3-circle-3 {
    width: 95vw;
    height: 250px;
    margin: 0;
    padding: 0;
    gap: 0px;
    opacity: 0;
  }
  .cube {
    transform: scale(0.5);
  }

  .icon-text {
    margin-top: 100px;
  }

  .coords-text-wrapper,
  .region-text-wrapper,
  .species-text-wrapper,
  .random-text-wrapper {
    font-size: 0.75rem;
  }

  .sightings {
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    gap: 0px;
    /* margin-top: 50px; */
    flex-wrap: none;
  }

  .sighting-wrapper {
    align-items: center;
    width: 95vw;
    padding: 0;
  }

  .portrait-wrapper,
  .portrait {
    width: 95vw;
  }

  .sightings-header {
    width: 100vw;
  }

  .query-location {
    font-size: 0.75rem;
  }

  .query-date .sighting-wrapper {
    width: 100vw;
    transition-duration: 0.5s;
  }

  .portrait-wrapper {
    width: 95vw;
  }

  .sighting-wrapper {
    transition-duration: 0.5s;
  }

  .sighting-wrapper:nth-child(1) {
    margin-left: 0;
  }

  .sighting-wrapper:nth-last-child(-n + 1) {
    margin-right: 0;
  }

  .sighting-wrapper:hover {
    opacity: 100%;

    margin-bottom: 0px;
  }

  .sighting-wrapper:hover > .sighting-bottom {
    opacity: 100%;
    transition-duration: 0.2s;
  }

  .sighting-bottom {
    width: 95vw;
    margin-top: -150px;
  }

  .com-name {
    font-size: 1.5rem;
  }

  .speciesCode {
    font-size: 0.75rem;
  }

  .location-wrapper {
    width: 90vw;
  }

  .location-name {
    font-size: 0.75rem;
  }

  .number-wrapper {
    height: 20px;
    width: 20px;
    /* margin-bottom: 10px; */
  }

  .number {
    font-size: 0.65rem;
    /* margin-bottom: 10px; */
  }

  .no-img {
    width: 95vw;
  }

  .lds-ellipsis {
    width: 95vw;
  }
  .lds-ellipsis div {
    margin-left: 160px;
    margin-top: 355px;
  }

  .citation {
    text-align: center;
  }
}

.cube {
  width: 225px;
  height: 300px;
  margin-bottom: 20px;
}
.face {
  transform-origin: 0 0;
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid rgb(0, 0, 0);
  font-size: 2rem;
  text-align: center;
  line-height: 100px;
  color: rgba(255, 255, 255, 0.704);
}
.front {
  background: rgb(255, 255, 255);

  transform: rotate(-30deg) skewX(-30deg) translate(130px, 172px) scaleY(0.864);
}
.top {
  background: rgb(255, 255, 255);

  transform: rotate(210deg) skew(-30deg) translate(-200px, -60px) scaleY(0.864);
}
.side {
  background: rgb(255, 255, 255);

  transform: rotate(90deg) skewX(-30deg) scaleY(0.864) translate(68px, -131px);
}
